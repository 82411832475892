import React from "react"
import "./PressKit.css"
import image3 from "../../../images/page2Img/img2.png"
import img from "../../../images/page2Img/Component.svg"
import Footer from "../../components/Footer"
import AppBarNew from "../../components/Appbar"
import Head from "../../components/Head"

function PressKit() {
  return (
    <>
      <Head pageTitle="Press Kit" />
      <AppBarNew />
      <div>
        <img src={image3} className="img3" alt="" />
        <div className="p2text">Press</div>
        <div className="p2text2">PressKit</div>
      </div>
      <div className="left">
        <p className="p2disc">
          If you by the low chances are interested in writing <br />
          about us. Down below we have press images.{" "}
        </p>
        <div>
          <button className="p2button">Download presskit</button>
        </div>
      </div>
      <img src={img} className="p2img" alt="" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default PressKit
